.consumables {

  .modelTable {
    display: none;
  }

  .searches {
    display: none;
  }

  .searches.processed {
    display: block;
  }
}

.vathidden {
  display: none !important;
}
